import React, { useState, useContext, useRef } from "react";
import { BsUpload } from "react-icons/bs";
import AWS from "aws-sdk";
import { HotelContext } from "../../../context/HotelContext";
import { PiFilePdfThin } from "react-icons/pi";

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const FileUploadBrochure = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [files, setFiles] = useState(
    formData?.overview?.additionalDetails?.brochureFiles || []
  );
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({});
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);

  const handleFileUpload = (file, type) => {
    setUploading(true);
    setError("");
    console.log(file, "this is file");
    const isPDF = file.type === "application/pdf"; // Check if the file is a PDF

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${type}/${file.name}`,
      ContentType: isPDF ? "application/pdf" : file.type, // Set Content-Type for PDFs
    };

    myBucket
      .upload(params, (err, data) => {
        setUploading(false);
        if (err) {
          console.error("Error uploading file:", err.message);
          setError("Error uploading file. Please try again.");
          return;
        }

        // Update formData with the uploaded file URL
        setFormData((prevFormData) => ({
          ...prevFormData,
          overview: {
            ...prevFormData.overview,
            additionalDetails: {
              ...prevFormData.overview.additionalDetails,
              brochureFiles: [
                ...(prevFormData.overview.additionalDetails.brochureFiles ||
                  []),
                data.Location,
              ],
            },
          },
        }));

        // Add the new file to state
        setFiles((prevFiles) => [...prevFiles, data.Location]);
        setProgress((prevProgress) => ({ ...prevProgress, [file.name]: 0 }));
      })
      .on("httpUploadProgress", (evt) => {
        setProgress((prevProgress) => ({
          ...prevProgress,
          [evt.key]: Math.round((evt.loaded / evt.total) * 100),
        }));
      });
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    selectedFiles.forEach((file) => {
      if (file.size > 5 * 1024 * 1024) {
        setError("File size should not exceed 5 MB.");
        return;
      }
      if (!allowedTypes.includes(file.type)) {
        setError("PNG, JPG, and PDF files are allowed.");
        return;
      }
      handleFileUpload(file, "brochure");
    });
  };

  const handleRemoveFile = (fileUrl) => {
    setFiles((prevFiles) => prevFiles.filter((url) => url !== fileUrl));
    setFormData((prevFormData) => ({
      ...prevFormData,
      overview: {
        ...prevFormData.overview,
        additionalDetails: {
          ...prevFormData.overview.additionalDetails,
          brochureFiles:
            prevFormData.overview.additionalDetails.brochureFiles.filter(
              (url) => url !== fileUrl
            ),
        },
      },
    }));
  };

  return (
    <div className="w-full p-2 bg-white rounded-lg">
      <h2 className="text-2xl font-medium text-darkGray text-base mb-4">
        Brochure
      </h2>
      {formData.overview?.additionalDetails?.errors?.brochureFiles && (
        <p className="text-red-500 pb-3">
          {formData.overview.additionalDetails.errors.brochureFiles}
        </p>
      )}
      <div className="flex flex-wrap justify-between gap-5 items-start">
        <div className="w-full md:w-1/2">
          <p className="text-secondary text-sm mb-1">
            Upload Brochure Document
          </p>
          <div className="w-4.5/5 h-40 relative border-dashed border-2 border-gray-300 rounded-lg p-6 py-2 flex flex-col items-center justify-center">
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.pdf"
              className="opacity-0 absolute cursor-pointer w-full h-full"
              id="file-upload"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer text-center text-lg text-red-500"
            >
              <BsUpload
                className="mx-auto mb-2 font-semibold text-gray-400"
                size={30}
              />
              <div className="flex flex-wrap justify-center gap-x-2 items-center w-full">
                <p className="mb-1 font-medium underline">Click to upload</p>
                <p className="text-offGray">or drag and drop</p>
              </div>
              <p className="text-secondary text-xs mt-2">
                PDF, PNG, JPG (Up to 5 MB each)
              </p>
            </label>
          </div>
        </div>

        {files.length > 0 && (
          <div className="w-full mt-6 flex flex-wrap gap-4">
            {files.map((fileUrl, index) => (
              <div
                key={index}
                className="relative w-[100px] h-[100px] border rounded-lg overflow-hidden flex bg-gray-100"
              >
                {fileUrl.endsWith(".pdf") ? (
                  <PiFilePdfThin size={30} className="m-auto" />
                ) : (
                  <img
                    src={fileUrl}
                    alt="Uploaded"
                    className="w-full h-full object-cover"
                  />
                )}
                <button
                  className="absolute top-0 right-0 mt-2 mr-2 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center"
                  onClick={() => handleRemoveFile(fileUrl)}
                >
                  <img src="/close.svg" alt="Close" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploadBrochure;
