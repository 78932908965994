import axios from "axios";
import { base_URL } from "../config/config";
const vendorId = localStorage?.getItem("vendorId");

const API_BASE_URL = `${base_URL}/api`;
// Retry operation utility function
const retryOperation = async (operation, maxRetries = 3, delay = 1000) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error) {
      console.error(`Attempt ${i + 1} failed:`, error);
      if (i === maxRetries - 1) throw error;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};
export const register = async (signupData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/vendor/auth/register`,
      signupData
    );
    return response.data;
  } catch (error) {
    console.error("Error registering venue:", error);


    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "An error occurred during registration."
      );
    } else {
      throw new Error(
        "An error occurred during registration. Please try again later."
      );
    }
  }
};


export const updateOnboarding = async (onBoardingDetails) => {
  return retryOperation(async () => {
    try {
      const vendorId = localStorage.getItem("vendorId");


      if (!vendorId) {
        console.log("Vendor ID not found, skipping update");
        return null;
      }


      const response = await axios.put(
        `${API_BASE_URL}/vendor/updateOnboarding/${vendorId}`,
        { onBoardingDetails }
      );


      return response.data;
    } catch (error) {
      console.log("UpdateOnboarding attempt failed:", error?.message);
      return null;
    }
  });
};


export const updateOnboardingbytoken = async (
  onBoardingDetails,
  refreshToken
) => {
  try {
    // Proceed with the update if vendorId is available, or if the backend will find it using the refreshToken
    const response = await axios.put(
      `${API_BASE_URL}/vendor/getByEmail/${refreshToken}`,
      { onBoardingDetails }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "An error occurred during update."
      );
    } else {
      throw new Error(
        "An error occurred during update. Please try again later."
      );
    }
  }
};
export const getOnboarding = async (vendorId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/vendor/getOnboardingDetails/${vendorId}`
    );
    return response.data?.onboarding;
  } catch (error) {
    console.error("Error fetching onboarding details:", error);
  }
};
export const getCurrentVendor = async (vendorId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/vendor/get-vendor/${vendorId}`
    );
    return response.data.vendor;
  } catch (error) {
    console.error("Error fetching vendor details:", error);
  }
};
