import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/webComps/Navbar";
import HowWorks from "./HowWorks";
import Enquiries from "./Enquiries";
import Effortless from "./Effortless";
import Feedback from "./Feedback";
import GetStart from "./GetStart";
import Footer from "../../components/webComps/Footer";
import LandingPageMarquee from "../../components/LandingPageMarquee";
import { Typography, Box, Button, useMediaQuery } from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// const words = ["Venue", "Ballroom", "Auditorium", "Hotel", "Restaurant"];
import { useTheme } from "@mui/material/styles";
import GradientBlob from "../../components/GradientBlob";
const words = ["Venue", "Ballroom", "Auditorium", "Hotel", "Restaurant"];
const ListVenues = () => {
  const heroAfterRef = useRef(null);
  const isLoggedIn = !!localStorage.getItem("accessToken");
  const checkPayment = !!localStorage.getItem("payment");
  const scrollToHeroAfter = () => {
    if (heroAfterRef.current) {
      heroAfterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const theme = useTheme();
  const [index, setIndex] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLG = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-hidden">
      <div ref={heroAfterRef}>
        <Navbar />
      </div>

      <div
        style={{
          minHeight: "75vh",
          maxWidth: "100vw",
          width: "100%",
        }}
      >
        <Box
          className="arc-container"
          sx={{
            borderBottomLeftRadius: { xs: "100% 20%", md: "50% 20%" },
            borderBottomRightRadius: {
              xs: "100% 20%",
              md: "50% 20%",
            },
            width: "100%",
            display: "Flex",
            flexDirection: "column",
            alignItems: { xs: "left", md: "center" },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mt: { xs: 3, md: 3 },
                fontWeight: "700",
                fontSize: { xs: "1.7rem", sm: "2rem", md: "3rem" },
                ml: { xs: -20, md: -20 },
                pl: { xs: 3, sm: 11, md: 0 },
              }}
            >
              List your
            </Typography>
            <div className="word-container">
              <TransitionGroup component={null}>
                <CSSTransition
                  key={words[index]}
                  timeout={500}
                  classNames="word"
                >
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      ml: { xs: "5px" },
                      mt: 3,
                      fontWeight: "700",
                      color: "primary.main",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      fontSize: {
                        xs: "1.7rem",
                        sm: "2rem",
                        md: "3rem",
                      },
                    }}
                  >
                    {words[index]}
                  </Typography>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </Box>
          <Box style={{ display: "flex", width: "100%" }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontWeight: "700",
                fontSize: { xs: "1.2rem", sm: "2rem", md: "3rem" },
                px: { xs: 3, sm: 11, md: 0 },
                textAlign: "center",
                width: "100%",
              }}
            >
              for a profitable opportunity!
            </Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: "400",
                fontSize: { xs: "1rem", sm: "1.1rem", md: "1.3rem" },
                px: { xs: 3, sm: 11, md: 0 },
                textAlign: "center",
              }}
            >
              Join thousands of hosts renting their space for events on{" "}
              <b>Find my Venue</b>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 2,
            }}
          >
            <Button
              variant="contained"
              size="large"
              disabled={buttonDisabled}
              sx={{
                height: 50,
                padding: "10px 24px",
                textTransform: "none",
                borderRadius: 4,
                fontSize: "1.25rem",
                fontWeight: 500,
                backgroundColor: buttonDisabled
                  ? "#B0B0B0"
                  : theme.palette.primary.main,
                color: buttonDisabled ? "#FFFFFF" : "white",
                "&:hover": {
                  backgroundColor: buttonDisabled
                    ? "#B0B0B0"
                    : theme.palette.primary.dark,
                },
              }}
              onClick={() => {
                window.location.href = `${
                  isLoggedIn && checkPayment ? "/dashboard" : "/register"
                }`;
              }}
              fullWidth={isLG}
            >
              Register here
            </Button>
          </Box>
        </Box>

        <div className="marquee-container">
          <LandingPageMarquee />
        </div>
        {isMd ? null : (
          <>
            <GradientBlob position="left"></GradientBlob>
            <GradientBlob position="right" top={70}></GradientBlob>
          </>
        )}
      </div>
      {/* <div className="marquee-container">
        <LandingPageMarquee />
        {isMd ? null : (
          <>
            <GradientBlob position="left"></GradientBlob>
            <GradientBlob position="right" top={70}></GradientBlob>
          </>
        )}
      </div> */}

      <HowWorks />
      <Enquiries />
      <Effortless />
      {/* <GetStart /> */}
      {/* <Feedback /> */}
      <Footer onScrollDownClick={scrollToHeroAfter} />
    </div>
  );
};

export default ListVenues;
