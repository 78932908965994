import React, { useState, useContext } from 'react';
import AWS from 'aws-sdk';
import { VenueContext } from '../../context/VenueContext';
import Loader from '../shared/common/loader';
import { Divider } from '@mui/material';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import {
	DragDropContext,
	Droppable,
	Draggable,
} from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
// Configure AWS SDK
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
	params: { Bucket: S3_BUCKET },
	region: REGION,
});

const UploadPhotosVideos = () => {
	const { formData, setFormData } = useContext(VenueContext);
	const [videoLink, setVideoLink] = useState('');
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [coverPhoto, setCoverPhoto] = useState(
		formData.imagesAndVideos?.coverPhoto || ''
	);
	const [images, setImages] = useState(
		formData?.imagesAndVideos?.images || []
	);

	const handleFileUpload = (file, type) => {
		setUploading(true);

		const params = {
			ACL: 'public-read',
			Body: file,
			Bucket: S3_BUCKET,
			Key: `${type}/${file.name}`,
		};

		myBucket
			.upload(params, (err, data) => {
				if (err) {
					setUploading(false);
					setProgress(0);
					return;
				}
				setFormData((prevFormData) => ({
					...prevFormData,
					imagesAndVideos: {
						...prevFormData.imagesAndVideos,
						[type]: [
							...(prevFormData.imagesAndVideos?.[type] || []),
							data.Location,
						],
					},
				}));
				setUploading(false);
				setProgress(0);
			})
			.on('httpUploadProgress', (evt) => {
				setProgress(Math.round((evt.loaded / evt.total) * 100));
			});
	};

	const handleImageUpload = (event) => {
		const files = Array.from(event.target.files);
		const allowedTypes = ['image/jpeg', 'image/png'];

		files.forEach(async (file) => {
			if (file) {
				if (file.size > 5 * 1024 * 1024) {
					toast.error('File size should not exceed 5 MB.');
					return;
				}
				if (!allowedTypes.includes(file.type)) {
					toast.error('Only PNG and JPG files are allowed.');
					return;
				}
				await handleFileUpload(file, 'images');
			}
		});
	};

	const handleFileDrop = (event, type) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		if (type === 'images') {
			handleImageUpload({ target: { files } });
		} else if (type === 'videos') {
			handleVideoUpload({ target: { files } });
		}
	};
	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleVideoUpload = (event) => {
		const file = event.target.files[0];
		if (!file) return;

		if (file.type !== 'video/mp4') {
			toast.error('Only MP4 videos are allowed.');
			return;
		}

		if (file.size > 150 * 1024 * 1024) {
			// 150MB
			toast.error('Video size should not exceed 150MB.');
			return;
		}

		if ((formData.imagesAndVideos?.videos || []).length >= 30) {
			toast.error('You can upload up to 30 videos.');
			return;
		}

		handleFileUpload(file, 'videos');
	};

	const isValidVideoLink = (url) => {
		const canvaPattern =
			/(?:https?:\/\/)?(?:www\.)?(canva\.com\/[\w-]+)/;
		const vimeoPattern =
			/(?:https?:\/\/)?(?:www\.)?(vimeo\.com\/\d+)/;
		const youtubePattern =
			/(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]+/;

		return (
			canvaPattern.test(url) ||
			vimeoPattern.test(url) ||
			youtubePattern.test(url)
		);
	};

	// set cover photo
	const handleCoverPhotoSelection = (image, index) => {
		// Allow only the first image to be set as the cover photo
		if (index === 0) {
			setCoverPhoto(image);
			setFormData((prevFormData) => ({
				...prevFormData,
				photoVideo: {
					...prevFormData.photoVideo,
					coverPhoto: image,
				},
			}));
		} else {
			toast.error(
				'Only the first image can be set as the cover photo.'
			);
		}
	};

	const handleOnDragEnd = (result) => {
		if (!result.destination) {
			return; // Drop was outside the droppable area
		}

		// Handle the reordering logic
		const updatedImages = Array.from(formData.imagesAndVideos.images);
		const [movedImage] = updatedImages.splice(result.source.index, 1);
		updatedImages.splice(result.destination.index, 0, movedImage);

		setFormData((prevData) => ({
			...prevData,
			imagesAndVideos: {
				...prevData.imagesAndVideos,
				images: updatedImages,
			},
		}));
	};

	const handleVideoLinkUpload = async () => {
		const urlDetails = extractVideoDetails(videoLink);
		if (videoLink && isValidVideoLink(videoLink) && urlDetails) {
			setFormData((prevFormData) => ({
				...prevFormData,
				imagesAndVideos: {
					...prevFormData.imagesAndVideos,
					videoLinks: [
						...(prevFormData.imagesAndVideos?.videoLinks || []),
						videoLink,
					],
				},
			}));
			setVideoLink('');
		} else {
			toast.error(
				'Please enter a valid YouTube, Vimeo, or Canva link.'
			);
		}
	};

	const removeImage = (index) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			imagesAndVideos: {
				...prevFormData.imagesAndVideos,
				images: (prevFormData.imagesAndVideos?.images || []).filter(
					(_, i) => i !== index
				),
			},
		}));
	};

	const removeVideo = (index) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			imagesAndVideos: {
				...prevFormData.imagesAndVideos,
				videos: (prevFormData.imagesAndVideos?.videos || []).filter(
					(_, i) => i !== index
				),
			},
		}));
	};

	const removeVideoLink = (index) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			imagesAndVideos: {
				...prevFormData.imagesAndVideos,
				videoLinks: (
					prevFormData.imagesAndVideos?.videoLinks || []
				).filter((_, i) => i !== index),
			},
		}));
	};

	const extractVideoDetails = (url) => {
		// Implement video details extraction logic if needed
		return {
			id: '',
			title: '',
			url: '',
			thumbnail: '',
		};
	};

	const errors = formData?.imagesAndVideos?.errors || {};

	return (
		<div className='bg-white rounded-lg p-6 w-full mx-auto'>
			<h2 className='text-2xl font-semibold text-neutralBlack mb-4'>
				Upload Photos & Videos
			</h2>
			<p className='text-sm text-gray-500 mb-6'>
				Upload up to 30 photos & videos
			</p>

			<div className='mb-6'>
				<label
					htmlFor='imageUpload'
					className='block text-secondary text-sm mb-2'
				>
					Image Upload <span className='text-red-500 ml-1'>*</span>
				</label>
				<p className='text-sm text-offGray mb-4'>
					Make sure the size of the photos should not be more than 5MB
					(PNG, JPG). Select a photo so you can edit it, or drag it to
					change the order,
				</p>
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<StrictModeDroppable
						droppableId='images'
						direction='horizontal'
					>
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className='flex flex-wrap items-end gap-4 mb-4'
								onDrop={(e) => handleFileDrop(e, 'images')}
								onDragOver={handleDragOver}
							>
								{(formData?.imagesAndVideos?.images || []).map(
									(image, index) => (
										<Draggable
											key={`image-${index}`} // Unique key based on index
											draggableId={`image-${index}`} // Unique draggable ID
											index={index}
										>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={`relative ${
														index === 0
															? 'w-[160px] h-[160px]' // Larger size for the first image
															: 'w-[100px] h-[100px]'
													}`}
												>
													<img
														src={image}
														alt={`uploaded-${index}`}
														className='w-full h-full object-cover rounded-xl'
														onClick={() =>
															handleCoverPhotoSelection(image)
														}
													/>
													{index === 0 && (
														<div className='flex items-center justify-center'>
															<span className='absolute bottom-3 mx-auto bg-black/65 text-white text-[14px] px-2 py-1 rounded-2xl'>
																Cover
															</span>
														</div>
													)}
													<button
														className='absolute top-1 right-1 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center'
														onClick={() => removeImage(index)}
													>
														<img src='/close.svg' alt='close' />
													</button>
												</div>
											)}
										</Draggable>
									)
								)}
								{provided.placeholder}
								<div className='relative w-[100px] h-[100px]'>
									<input
										type='file'
										accept='.jpg,.jpeg,.png'
										className='hidden'
										onChange={handleImageUpload}
										id='imageUpload'
										multiple
									/>
									<label
										htmlFor='imageUpload'
										className='absolute inset-0 flex justify-center items-center cursor-pointer bg-[#FFF5F5] border border-dashed border-primary rounded-lg p-4'
									>
										<img src='/plus-primary.svg' alt='plus' />
									</label>
								</div>
							</div>
						)}
					</StrictModeDroppable>
				</DragDropContext>

				{errors.images && (
					<p className='text-red-500 text-base pt-1'>
						{errors.images}
					</p>
				)}
			</div>

			<div className='mb-6'>
				<label
					htmlFor='videoUpload'
					className='block text-secondary text-sm mb-2'
				>
					Video Upload
				</label>
				<p className='text-sm text-offGray mb-2'>
					Make sure the size of the Videos should not be more than
					150MB. (MP4)
				</p>
				<div
					className='flex flex-wrap gap-4 mb-4'
					onDrop={(e) => handleFileDrop(e, 'videos')}
					onDragOver={handleDragOver}
				>
					{(formData.imagesAndVideos?.videos || []).map(
						(video, index) => (
							<div key={index} className='relative w-24 h-24'>
								<video
									src={video}
									className='w-full h-full object-cover rounded-lg'
									controls={false}
								/>
								<button
									className='absolute top-1 right-1 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center'
									onClick={() => removeVideo(index)}
								>
									<img src='/close.svg' alt='close' />
								</button>
							</div>
						)
					)}

					<div className='border relative border-dashed border-red-500 rounded-lg p-4 flex justify-center items-center cursor-pointer w-24 h-24 bg-[#FFF5F5]'>
						<input
							type='file'
							accept='video/*'
							className='opacity-0 absolute h-full cursor-pointer w-full '
							onChange={handleVideoUpload}
							id='videoUpload'
						/>
						<label
							htmlFor='videoUpload'
							className='flex flex-col items-center cursor-pointer'
						>
							<img src='/plus-primary.svg' alt='plus' />
						</label>
					</div>
				</div>
			</div>

			<Divider className='text-secondary text-sm'>or</Divider>

			<div className='mb-10'>
				<label
					htmlFor='videoLink'
					className='block text-secondary text-sm mb-2'
				>
					Video Link
				</label>
				<div className='flex items-center border border-offGray rounded-lg overflow-hidden w-full max-w-sm'>
					<input
						value={videoLink}
						onChange={(e) => setVideoLink(e.target.value)}
						type='text'
						id='videoLink'
						className='flex-grow p-2 border-none focus:outline-none text-sm'
						placeholder='Paste video URL here'
					/>
					<button
						onClick={handleVideoLinkUpload}
						className='p-2 text-primary '
					>
						Upload
					</button>
				</div>
				<div className='flex flex-wrap gap-4 mt-4'>
					{(formData.imagesAndVideos?.videoLinks || []).map(
						(link, index) => (
							<div key={index} className='relative w-24 h-24'>
								<ReactPlayer
									url={link}
									className='w-full h-full object-cover rounded-lg'
									width='100%'
									height='100%'
									controls={false}
								/>
								<button
									className='absolute top-1 right-1 bg-[#BDBDBD] rounded-[4px] p-1 text-white w-[24px] h-[24px] flex items-center justify-center'
									onClick={() => removeVideoLink(index)}
								>
									<img src='/close.svg' alt='close' />
								</button>
							</div>
						)
					)}
				</div>
				{errors.videoLinks && (
					<p className='text-red-500 text-base pt-1'>
						{errors.videoLinks}
					</p>
				)}
			</div>

			{uploading && (
				<div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50'>
					<Loader />
					<p className='text-white mt-4'>Uploading: {progress}%</p>
				</div>
			)}
		</div>
	);
};

export default UploadPhotosVideos;
